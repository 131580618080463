.rbc-event {
    border-radius: 16px !important;
}

.rbc-event-continues-after {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rbc-event-continues-prior {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.event-category-event {
    background-color: rgba(13, 110, 253, 1) !important;
}

.event-category-offline {
    background-color: rgba(138, 43, 226, 1) !important;
}

.event-category-exhibition {
    background-color: rgba(255, 215, 0, 1) !important;
}