.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Viewport height: 화면 전체 높이를 차지 */
  text-align: center; /* 텍스트를 가운데 정렬 */
}

h1, p {
  margin: 0;
}
